
.bgSlider {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    z-index: -100;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    transition: all 0.5s ease-in-out;
    transform: translateX(0px);
}
.bgSlider img {
    transition: opacity 1s ease-in-out;
}
.bgSlider img:active {
    opacity: 1;
}
.overlay {
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
}
.season {
    position: fixed;
    color: white;
    top: 90px;
    font-size: 23px;
    left: 18rem;
    top: 11rem;
    font-weight: 200;
    letter-spacing: 5px;
}
.houseLocation {
    color: white;
    top: 90px;
    font-size: 90px;
    left: 18rem;
    top: 14rem;
    font-weight: 800;
}
.geoPosition {
    color: white;
    top: 90px;
    font-size: 90px;
    left: 18rem;
    top: 20rem;
    font-weight: 800;
}
.activeNumber {
    color: white;
    top: 90px;
    font-size: 40px;
    right: 14rem;
    top: 20rem;
    font-weight: 200;
}
.totalNumber {
    color: darkgray;
    top: 90px;
    font-size: 30px;
    right: 11.5rem;
    top: 20.5rem;
    font-weight: 400;
}
.dot {
    font-size: 5rem;
    right: 1rem;
    top: 15.5rem;
    position: fixed;
    padding: 0 !important;
    margin: 0 !important;
    height: 10px !important;
    list-style-type: disc;
}
.dot li {
    padding: 0 !important;
    margin: 0 !important;
    height: 50px !important;
}
.notActiveDot {
    color: darkgray;
}
.activeDot {
    color: white !important;
}
.socialLogo {
    color: white;
    list-style-type: none;
    font-size: 20px;
    left: 1rem;
    bottom: 1.5rem;
    position: fixed;
    transition: all 1s ease;
}
.socialLogo li {
    transition: all 0.5s ease-in-out;
}
.socialLogo li:hover {
    font-size: 25px;
    left: 1rem;
    bottom: 1.5rem;
    cursor: pointer;
}
.imageBoxes ul {
    background-color: white;
    position: fixed;
    bottom: 0;
    right: 0;
    list-style-type: none;
    display: flex;
    padding: 0 !important;
    margin: 0 !important;
}
.imageBoxes ul li {
    border-right: 0.7px solid black;
    width: 200px;
    padding: 40px 20px;
    transition: all 1s ease;
}
.imageBoxes ul li:hover {
    background-color: black;
    z-index: -100;
    cursor: pointer;
    color: white;
}
.activeImageBoxes {
    background-color: black;
    z-index: -100;
    cursor: pointer;
    color: white;
    background-color: black;
    z-index: -100;
    cursor: pointer;
    color: white;
}
.imageBoxes ul li p {
    padding: 0 !important;
    margin: 0 !important;
    font-size: 16px;

}
.imageBoxNumber {
    font-weight: 800;
}
.imageBoxTitle {
    font-weight: 600;
}
.imageBoxSeason {
    font-weight: 400;
}

.buttonViewProject {
    text-decoration: none;
    background-color: black;
    color: white;
    padding: 15px 40px;
    position: fixed;
    top: 90px;
    font-size: 15px;
    left: 18rem;
    top: 29rem;
    font-weight: 400;
    border-radius: 1px;
    transition: all 1s ease-out;
    transform: scaleX(3px);
}
.buttonViewProject:hover {
    border-left: 5px solid white;
    cursor: pointer;
}
.buttonViewProject i {
    padding-left: 7px;
}
