.headerStyle{
    height: max-content;
    backdrop-filter: blur(5px);
    margin: 0px !important;
    padding: 0px !important;

}
ul{
    margin: 0px;
}
.headerStyle ul li{
    list-style-type: none;
    display: inline-flex;
    margin: 30px 25px;
}
.headerMenu p{
    text-decoration: none;
    padding-bottom: 5px;
    font-weight: 200;
    color: white;
}
.headerMenu p:hover{
    border-bottom: 2px solid white;
    padding-bottom: 10px;
    transition: border 500ms ease;
    cursor: pointer;
}
.headerMenu p.active{
    border-bottom: 2px solid white;
    padding-bottom: 10px;

}
.clicked{
    border-bottom: 2px solid white;
    padding-bottom: 10px;
    transition: border 500ms ease;
}
.coName p{
    font-size: 23px;
    color: white;
    font-weight: 500;  
    text-decoration: none;
    border-right: 2px solid white;
    line-height: 50px;
    padding-right: 40px;
}
.coName p:hover{
    cursor: pointer;
}

