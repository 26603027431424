
.galleryImagesRow{
    width: 100%;
    position: fixed;
    top: 5rem;
    margin: 50px auto;
    display: flex;
    justify-content: center;
    max-height: 100vh;
    overflow-y: scroll;
    bottom: 0px;
}
.galleryImages{
    width: fit-content;
   
}
.galleryImages img{
    width: 300px;
    padding: 10px;
    margin: 10px 0px;
    transition:all 1s ease-out !important;
    /* transform: translateX(10px) !important; */
    opacity: 0.8 !important;
    display: flex;
    flex-wrap: wrap;
}

.galleryImages img:hover {
    opacity: 10 !important;
    /* background-color: black; */
    cursor: pointer;
    width: calc(300px + 100px);
    display: black;
    z-index: -100;
}
.imageDescribtion{
    font-size: 18px;
    color: white;
    text-align: center;
    font-weight: 500;
    transition: all 1s ease-in-out;
}
.galleryImages img:hover + .imageDescribtion {
    font-size: 20px;
 
}
.galleryImages:hover{
    background-color: black;
    width: fit-content;
    height: 300px;
} 
.overflowSett{
    display: flex;
    overflow-y: scroll;
    height: 100vh;
}
.activeImage {
    background-color: black;
    opacity: 0.3;
}
.imageOverlay {
    background-color: white;
    position: absolute;
    opacity: 0.3;
}
